<template>
    <v-main class="mobile ma-0 pa-0" >
        <v-slide-group mobile-breakpoint="1440" class="mb-12 "
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @click:next="setIntervalSlide(15000)"
            @click:prev="setIntervalSlide(15000)"
            :show-arrows="!$config.mobile()"
            v-model="slide"
            >
            <v-slide-item v-for="(item, index) in imagens.tags" :key="index">
                <div class="mobile-image">
                    <v-card class="ma-4 ml-0" height="335" width="500" style="overflow: hidden;">
                        <v-img  :src="item" />
                    </v-card>
                </div>
            </v-slide-item>
        </v-slide-group>
    </v-main>
</template>

<script>
    export default {
        props: ["imagens"],
        data() {
            return {
                slide: 0,
                interval: null
            }
        },
        methods: {
            setIntervalSlide(time){
                const self = this
                clearInterval(self.interval)
                self.interval = setInterval(() => {
                    self.slide++
                    if(self.slide >= self.imagens.tags.length){
                        self.slide = 0
                    }
                    clearInterval(self.interval)
                    self.setIntervalSlide(5000)
                }, time)
            }
        },
        mounted(){
            this.setIntervalSlide(5000)
        }
    }
</script>

<style>
    .mdi-menu-right, .mdi-menu-left { 
        font-size: 60px !important;
    }
</style>

<style scoped>
    .mobile {
        min-width: 991px;
    }
    @media(max-width: 479px) {
        .mobile {
            width: 330px !important;
        }
        .mobile-image{
            width: 280px;
            height: 190px;
        }
    }
</style>